const defaultPlaceHolderImage: string = "https://www.publicdomainpictures.net/pictures/280000/velka/not-found-image-15383864787lu.jpg";

export const getImageUrlsFromCategory = (
  category: any,
  returnThumb: boolean = false
) => {
  if (returnThumb) {
    return category?.attributes?.product_image?.image_urls?.thumb;
  }
  if (category?.attributes?.product_image?.image_urls?.thumb) {
    return category.attributes.product_image?.image_urls
  }
  return category.attributes.product_image?.url
    ? category.attributes.product_image?.url
    : defaultPlaceHolderImage;
};

export const getImageUrls = (
  item: any,
  isNullReturnDefaultImage: boolean = true
  ) => {
  const defaultImage = getDefaultImage(item?.attributes?.images?.data);
  if (!isNullReturnDefaultImage) {
    return (
      defaultImage?.attributes?.image_urls ||
      defaultImage?.attributes?.url
    );
  }
  if (defaultImage?.attributes?.image_urls?.thumb) {
    return defaultImage?.attributes?.image_urls
  }
  return defaultImage?.attributes?.url
    ? defaultImage?.attributes?.url
    : defaultImage;
};


export const getLandingPageImageUrls = (
  item: any,
  isNullReturnDefaultImage: boolean = true
  ) => {
  const defaultImage = getDefaultImage(item);
  if (!isNullReturnDefaultImage) {
    return (
      defaultImage?.attributes?.image_urls ||
      defaultImage?.attributes?.url
    );
  }
  if (defaultImage?.attributes?.image_urls?.thumb) {
    return defaultImage?.attributes?.image_urls
  }
  return defaultImage?.attributes?.url
    ? defaultImage?.attributes?.url
    : defaultImage;
};

export const getDefaultImage = (item: any) => {
  if (Array.isArray(item)) {
    if (item.length > 1) {
      const defaultImage = item.find((image) => image?.attributes?.is_default);
      return defaultImage ? defaultImage : item[0];
    }
    return item[0]
  }
  return item
};

export const getImageUrlsFromBanner = (banner: any) => {
  if (banner?.attributes?.image_urls?.thumb) {
    return banner.attributes?.image_urls;
  }
  return banner.attributes?.url
    ? banner.attributes?.url
    : defaultPlaceHolderImage;
};

export const getImageUrlsForSlider = (item: any) => {
  return (
    item?.attributes?.image_urls?.thumb ||
    item?.attributes?.url ||
    defaultPlaceHolderImage
  )
};
export const getImageUrlsForCarousel = (item: any, type='thumb') => {
  return (
    item?.attributes?.image_urls?.[type] ||
    item?.attributes?.url ||
    defaultPlaceHolderImage
  )
};

export const getBackgroundImageFromProduct = (product: any) => {
  return (
    product?.attributes?.images.data[0]?.attributes.image_urls?.medium ||
    product?.attributes?.images.data[0]?.attributes.url ||
    defaultPlaceHolderImage
  )
};

export const getProductImageBySize = (
  product: any,
  type: string = "thumb",
  dataType: string = "images",
) => {
  const defaultImage = getDefaultImage(product?.attributes?.[dataType]?.data);
  if (defaultImage?.attributes?.image_urls?.[type]) {
    return defaultImage?.attributes?.image_urls?.[type]
  }
  return defaultImage?.attributes?.url
    ? defaultImage?.attributes?.url
    : defaultPlaceHolderImage;
};

export const getCartProductImageBySize = (
  imageData: any,
  type: string = "thumb",
) => {
  const defaultImage = getDefaultImage(imageData);
  if (defaultImage?.attributes?.image_urls?.[type]) {
    return defaultImage?.attributes?.image_urls?.[type]
  }
  return defaultImage?.attributes?.url
    ? defaultImage?.attributes?.url
    : defaultPlaceHolderImage;
};

export const getProductImageFromItem = (
  item: any,
  type: string = "thumb",
  isNullReturnDefaultImage: boolean = true
) => {
  if (!isNullReturnDefaultImage) {
    return item?.attributes?.image_urls?.[type] || item?.attributes?.url
  }
  return (
    item?.attributes?.image_urls?.[type] ||
    item?.attributes?.url ||
    defaultPlaceHolderImage
  );
};

export const getSingleProductImage = (data: any, type: string = "thumb") => {
  const defaultImage = getDefaultImage(data?.product_images?.data);
  return (
    defaultImage?.attributes?.image_urls?.[type] || defaultImage?.attributes?.url
  );
};
