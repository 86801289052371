import React from "react";

export interface UseIntersectionObserverProps {
  target: React.RefObject<any>;
  onIntersect: IntersectionObserverCallback;
  threshold?: number;
  rootMargin?: string;
  enabled?: any;
}

const useIntersectionObserver = ({
  target,
  onIntersect,
  threshold = 0.1,
  rootMargin = "0px",
  enabled = true,
}: UseIntersectionObserverProps) => {
  React.useEffect(() => {
    if (!enabled) {
      return;
    }
    const observer = new IntersectionObserver(onIntersect, {
      rootMargin,
      threshold,
    });
    const current = target && target.current;

    if (!current) {
      return;
    }

    observer.observe(current);

    return () => {
      observer.unobserve(current);
    };
  }, [enabled, onIntersect, rootMargin, threshold, target]);
};

export default useIntersectionObserver;
