import * as React from "react";
import useIntersectionObserver from "./useIntersectionObserver";

interface LazyImageProps {
  src:
    | {
        thumb: string;
        medium: string;
      }
    | string
    | undefined;
  noHeight?: boolean;
  lazy?: boolean;
  [key: string]: any;
}

export const LazyImageLoader: React.FunctionComponent<LazyImageProps> = (
  props,
) => {
  const { src, noHeight, lazy = true, containerParams, ...rest } = props;

  const ref = React.useRef(null);
  const [isVisible, setIsVisible] = React.useState(!lazy);

  useIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        setIsVisible(true);
        observerElement.unobserve(ref.current!);
      }
    },
  });

  return (
    <div
      // @ts-ignore
      {...containerParams}
      style={{ height: noHeight ? "" : "100%", ...containerParams?.style }}
      ref={ref}
    >
      {isVisible && typeof src === "object" ? (
        <picture>
          <source media="(max-width: 767px)" srcSet={src.thumb} />
          <img style={{ ...rest?.style }} src={src.medium} {...rest} />
        </picture>
      ) : (
        isVisible && (
          <img
            style={{ ...rest?.style }}
            src={src?.toString() || ""}
            {...rest}
          />
        )
      )}
    </div>
  );
};
