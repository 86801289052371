import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import {
  getCartProductImageBySize,
  getLandingPageImageUrls,
} from "../../../studio-store-ecommerce-theme/src/utils/image";
import { WithStyles } from "@material-ui/core/styles";
import {
  MessageConstants,
  getMessageName,
} from "../MessageConstants/MessageConstants";
// @ts-ignore
import { debounceLeading } from "../Utils/index";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  cart: any;
  wholeCart: any;
  cartId: any;
  loading: boolean;
  cartDrawerStatus: boolean;
  externalOpenCartDrawer: boolean;
  changeIsActive: boolean;
  cartQuantities: any;
  // Customizable Area Start
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class SwipeCartController extends BlockComponent<Props, S, SS> {
  getCartApiCallId: string = "";
  putUpdateCartQuantityApiCallId: string = "";
  delCartItemApiCallId: string = "";
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getMessageName(MessageConstants.setOpenCartDrawer),
      getMessageName(MessageConstants.updateShoppingCart),

      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      cart: [],
      wholeCart: "",
      cartId: "",
      loading: true,
      cartDrawerStatus: false,
      externalOpenCartDrawer: false,
      changeIsActive: false,
      cartQuantities: {},
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getCart();
    // Customizable Area Start
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.parseExpireTokenResponse(responseJson, this.state, this.props)) {
        if (responseJson) {
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
          if (apiRequestCallId != null) {
            // get cart
            if (apiRequestCallId === this.getCartApiCallId) {
              if (responseJson?.data) {
                this.setState({
                  cart: responseJson.data[0]?.attributes?.order_items,
                  cartId: responseJson.data[0]?.id,
                  wholeCart: responseJson.data[0]?.attributes,
                  loading: false,
                });
                window.localStorage.setItem(
                  "cart_length",
                  responseJson.data[0].attributes.order_items.length
                );
              }
              if (responseJson?.errors && responseJson.errors.length > 0) {
                if (
                  Object.keys(
                    JSON.parse(window.localStorage.getItem("buyNow") || "{}")
                  )?.length != 0
                ) {
                  this.setState({
                    cart: [],
                    cartId: "",
                    wholeCart: "",
                    loading: false,
                  });
                } else {
                  this.setState({
                    loading: false,
                  });

                  if (
                    responseJson?.errors[0]?.message !== "No order record found."
                  ) {
                    //@ts-ignore
                    window.notify([
                      {
                        type: "error",
                        message: responseJson.errors[0].message,
                      },
                    ]);
                  }
                }
                window.localStorage.removeItem("cart_length");
              }
            }

            if (apiRequestCallId === this.putUpdateCartQuantityApiCallId) {
              this.setState({
                loading: false,
              })
              if (responseJson?.data?.id) {
                if (
                  Object.keys(
                    JSON.parse(window.localStorage.getItem("buyNow") || "{}")
                  ).length == 0
                ) {
                  this.getCart();
                } else {
                  this.setState({
                    cart: responseJson.data?.attributes?.order_items,
                    cartId: responseJson.data?.id,
                    wholeCart: responseJson.data?.attributes,
                  });
                }
              } else {
                this.parseApiErrorResponse(responseJson);
                const errors = responseJson?.errors?.[0]?.order
                if (errors && errors !== "Sorry, Product is out of stock") {
                  // @ts-ignore
                  window.notify([
                    {
                      message: errors,
                      type: "danger",
                    },
                  ]);
                }
              }
            }

            if (apiRequestCallId === this.delCartItemApiCallId) {
              this.getCart();
              const cart_length = parseInt(window.localStorage.getItem("cart_length")!);
              // @ts-ignore
              window.localStorage.setItem("cart_length", cart_length - 1);
            }
          }
        }
      }
    } else if (
      getMessageName(MessageConstants.setOpenCartDrawer) === message.id
    ) {
      const cartDrawerStatus = message.getData(
        getMessageName(MessageConstants.openCartDrawer)
      );
      this.setState({
        cartDrawerStatus: cartDrawerStatus,
        externalOpenCartDrawer: cartDrawerStatus,
      });
      setTimeout(() => {
        this.setState({
          externalOpenCartDrawer: false,
        });
      }, 500);
    } else if (
      getMessageName(MessageConstants.updateShoppingCart) === message.id
    ) {
      const wholeCart = message.getData(
        getMessageName(MessageConstants.shoppingCartData)
      );
      this.setState({
        cart: wholeCart?.order_items,
        cartId: wholeCart?.id,
        wholeCart,
      });
    }
    // Customizable Area End
  }

  handleCloseCartDrawer = () => {
    if (window.location.href.includes("profilebio")) {
      const callWishlistApiMessage = new Message(
        getMessageName(MessageConstants.callWishlistApi)
      );
      runEngine.sendMessage(callWishlistApiMessage.id, callWishlistApiMessage);
    }
    if (this.state.cartDrawerStatus) {
      this.setState({ cartDrawerStatus: false });
    }
  };

  handleOpenCartDrawer = () => {
    this.setState({ cartDrawerStatus: true });
  };

  getCart = (): boolean => {
    const token = window.localStorage.getItem("token");
    this.setState({
      loading: true,
    });

    let headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCartApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetIsCartCreated
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleOnBlurCartQuantity = (
    productId: any,
    productVariant: any,
    quantity: any
  ) => {
    this.setState({
      changeIsActive: false,
    });
    if (!quantity || isNaN(parseInt(quantity, 10))) {
      return;
    }
    this.putUpdateCartQuantity(productId, productVariant, quantity);
  };
  handleOnFocusQuantity = () => {
    const quantities: { [key: string]: any } = {};
    this.state.cart.forEach(
      (item: {
        attributes: {
          catalogue_id: string;
          catalogue_variant_id: string;
          quantity: string;
        };
      }) => {
        quantities[
          `${item.attributes.catalogue_id}-${
            item?.attributes?.catalogue_variant_id ?? ""
          }`
        ] = item.attributes.quantity;
    });
    this.setState({
      changeIsActive: true,
      cartQuantities: quantities,
    });
  };

  putUpdateCartQuantityWithoutDebounce = (
    product_id: any,
    product_variant: any,
    quantity: any,
    type?: any
  ): boolean => {
    // Customizable Area End
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("token"),
    };

    let httpBody: any;
    if (type == "subscription") {
      httpBody = {
        subscription_quantity: quantity,
        catalogue_id: product_id,
        // catalogue_variant_id: product_variant,
      };
    } else {
      httpBody = {
        quantity: quantity,
        catalogue_id: product_id,
        catalogue_variant_id: product_variant,
      };
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.putUpdateCartQuantityApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiPutUpdateCartQuantity +
        `${this.state.cartId}/update_item_quantity`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
    // Customizable Area End
  };

  putUpdateCartQuantity = debounceLeading(this.putUpdateCartQuantityWithoutDebounce);

  decreaseCartQuantity = (product_id: any, product_variant: any, quantity: any) => {
    if (this.state.loading) {
      return
    }
    if (quantity > 1) {
      this.putUpdateCartQuantity(product_id, product_variant, quantity - 1);
    } else {
      this.deleteCartItem(product_id, product_variant);
    }
  };
  increaseCartQuantity = (product_id: any, product_variant: any, quantity: any) => {
    if (this.state.loading) {
      return
    }
    this.putUpdateCartQuantity(product_id, product_variant, quantity + 1);
  };

  deleteCartItem = (product_id: any, product_variant?: any): boolean => {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("token"),
    };
    const httpBody = {
      catalogue_variant_id: product_variant,
      catalogue_id: product_id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.delCartItemApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiPutUpdateCartQuantity +
        `${this.state.cartId}/delete_item`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.delAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
    // Customizable Area End
  };

  removeCartItem = (product_id: any, product_variant?: any): boolean => {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem("token"),
    };
    const httpBody = {
      catalogue_variant_id: product_variant,
      catalogue_id: product_id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.delCartItemApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiPutUpdateCartQuantity +
        `${this.state.cartId}/delete_item`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.delAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
    // Customizable Area End
  };

  setDefaultImage = (imageData: any) => {
    let currentImage: any;
    if (
      imageData?.catalogue_variant?.attributes?.images &&
      imageData?.catalogue_variant?.attributes?.images?.data.length > 0
    ) {
      currentImage = getLandingPageImageUrls(imageData?.catalogue_variant);
      if (imageData?.catalogue_variant?.attributes?.images.length == 1) {
        currentImage =
          imageData?.catalogue_variant?.attributes?.images?.data[0]?.attributes
            ?.url;
      } else {
        imageData?.catalogue_variant?.attributes?.images.data.map(
          (element: any, index: number) => {
            if (element?.attributes?.is_default) {
              currentImage = getLandingPageImageUrls(element);
            }
          }
        );
      }
    } else {
      currentImage = getCartProductImageBySize(imageData?.product_images?.data);
    }
    return currentImage;
  };
  // Customizable Area End
}
