import React from "react"
import "../css/announcement.css"

function Announcement() {
  const announcementBarContent = (
    <p className="announcement-bar-content">
      FREE SHIPPING WITHIN INDIA, AND ON ORDERS ABOVE $250 USD WORLDWIDE. SIGN UP TO GET FLAT 10%
      OFF YOUR VERY FIRST ORDER.
    </p>
  )
  return (
    <section
      id="section-announcement"
      data-testid="announcement-bar"
    >
      <div className="announcement-bar">
        <div className="announcement-bar-wrapper hide-at-mobile">{announcementBarContent}</div>
        <div className="show-at-mobile marquee">{announcementBarContent}</div>
      </div>
    </section>
  )
}

export default Announcement
