import MessageEnum, {
    getName
  } from '../../../../framework/src/Messages/MessageEnum';


export enum NewMessageConstants {
    updateTemplate,
    updateTemplateData,
    reGenerateGuestToken,
    updateNotification,
    NotificationCount,
    updateContactUs,
    updateContactUsData,
    openCartDrawer,
    setOpenCartDrawer,
    shoppingCartData,
    updateShoppingCart,
    callWishlistApi,
}


export const MessageConstants = {
    ...MessageEnum,
    ...NewMessageConstants
}
export const getMessageName = (myEnum: NewMessageConstants) => {
    return MessageConstants[myEnum];
};