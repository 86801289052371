Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.validationApiContentType = "application/json";
exports.endPointApiPutUpdateCartQuantity = "cart/carts/";
exports.endPointApiGetIsCartCreated = "cart/carts";
exports.endPointApiPostWishlist = "wishlist/wishlists";
exports.postAPiMethod = "POST";
exports.putAPiMethod = "PUT";
exports.delAPiMethod = "DELETE";
exports.getApiMethod = "GET";
// Customizable Area Start
// Customizable Area End