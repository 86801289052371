import React from "react";
import SwipeCartController from "./SwipeCartController.web";
import "./css/swipeCart.css";

import SlideButton from "../SlideButton/SlideButton";

import { SwipeableDrawer, ClickAwayListener } from "@material-ui/core";
import { LazyImageLoader } from "../LazyImageLoader/LazyImageLoader";

// Customizable Area Start
// Customizable Area End

export class SwipeCart extends SwipeCartController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    function renderProductPrice(variant: any) {
      return (
        <p className="drawer-cart-item-info-price">
          {
            JSON.parse(window.localStorage.getItem("countryCode") ?? "{}")
              .countryCode
          }{" "}
          {variant.product_on_sale
            ? variant.product_sale_price
            : variant.product_price}
        </p>
      );
    }
    // Customizable Area Start
    return (
      <ClickAwayListener
        onClickAway={() =>
          !this.state.externalOpenCartDrawer && this.handleCloseCartDrawer()
        }
      >
        <SwipeableDrawer
          anchor="right"
          variant="persistent"
          open={this.state.cartDrawerStatus}
          onClose={this.handleCloseCartDrawer}
          onOpen={this.handleOpenCartDrawer}
        >
          <div className="drawer-cart-main-container">
            <div className="sidebar-cart drawer-container drawer-container-bordered">
              <div className="drawer-header ">
                <div className="drawer-header-title">MY SHOPPING BAG</div>
                <div
                  className="drawer-close-icon cursor-pointer"
                  onClick={this.handleCloseCartDrawer}
                >
                  <svg
                    className="close-icon"
                    role="presentation"
                    viewBox="0 0 16 14"
                  >
                    <path
                      d="M15 0L1 14m14 0L1 0"
                      stroke="currentColor"
                      fill="none"
                      fillRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="drawer-container drawer-container-bordered">
              <p>
                Free shipping within India and on orders above $250 USD worldwide.
                Sign up to get flat 10% off your very first order.
              </p>
            </div>
            <div className="drawer-cart drawer-container drawer-container-bordered">
              <div className="drawer-main">
                {this.state.cart && this.state.cart?.length > 0 ? (
                  this.state.cart.map(
                    (item: {
                      attributes: {
                        product_images: { data: any };
                        id: any;
                        product_name?: string;
                        catalogue_id: number;
                        catalogue_variant_id: number;
                        quantity: number;
                        order_id: any;
                      };
                    }) => {
                      const defaultImageUrl = this.setDefaultImage(
                        item.attributes
                      );
                      return (
                        <div
                          className="drawer-cart-item"
                          key={item.attributes.id}
                        >
                          <div className="drawer-cart-item-img-box">
                            <LazyImageLoader
                              className="drawer-cart-item-img"
                              alt={item.attributes?.product_name}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                objectPosition: "center",
                              }}
                              src={defaultImageUrl}
                            />
                          </div>
                          <div className="drawer-cart-item-info">
                            <div className="drawer-cart-item-info-title">
                              <a href={`/shop/${item.attributes?.catalogue_id}`}>
                                {item.attributes?.product_name}
                              </a>
                            </div>
                            <div>{renderProductPrice(item.attributes)}</div>
                            <div className="drawer-cart-item-info-quantity">
                              <div className="drawer-cart-item-info-price-box">
                                <div
                                  className="drawer-cart-item-info-price-icon-wrapper"
                                  onClick={() =>
                                    this.decreaseCartQuantity(
                                      item?.attributes?.catalogue_id,
                                      item?.attributes?.catalogue_variant_id,
                                      item?.attributes?.quantity
                                    )
                                  }
                                >
                                  <svg
                                    className="drawer-cart-item-info-price-icon"
                                    role="presentation"
                                    viewBox="0 0 16 2"
                                  >
                                    <path
                                      d="M1,1 L15,1"
                                      stroke="currentColor"
                                      fill="none"
                                      fillRule="evenodd"
                                      strokeLinecap="square"
                                    />
                                  </svg>
                                </div>

                                <input
                                  type="text"
                                  className="drawer-cart-item-info-price-input"
                                  value={
                                    !this.state.changeIsActive
                                      ? item?.attributes?.quantity
                                      : this.state.cartQuantities[
                                          item.attributes?.catalogue_id
                                        ]
                                  }
                                  onFocus={this.handleOnFocusQuantity}
                                  onBlur={(e) =>
                                    this.handleOnBlurCartQuantity(
                                      item?.attributes?.catalogue_id,
                                      item?.attributes?.catalogue_variant_id,
                                      e.target.value
                                    )
                                  }
                                  disabled={this.state.loading}
                                />
                                <div
                                  className="drawer-cart-item-info-price-icon-wrapper"
                                  onClick={() =>
                                    this.increaseCartQuantity(
                                      item?.attributes?.catalogue_id,
                                      item?.attributes?.catalogue_variant_id,
                                      item?.attributes?.quantity
                                    )
                                  }
                                >
                                  <svg
                                    className="drawer-cart-item-info-price-icon"
                                    role="presentation"
                                    viewBox="0 0 16 16"
                                  >
                                    <g
                                      stroke="currentColor"
                                      fill="none"
                                      fillRule="evenodd"
                                      strokeLinecap="square"
                                    >
                                      <path d="M8,1 L8,15" />
                                      <path d="M1,8 L15,8" />
                                    </g>
                                  </svg>
                                </div>
                              </div>
                              <div
                                className="drawer-cart-item-info-remove drawer-cart-item-info-remove-underline"
                                onClick={() =>
                                  this.removeCartItem(
                                    item?.attributes?.catalogue_id,
                                    item?.attributes?.catalogue_variant_id
                                  )
                                }
                              >
                                Remove
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )
                ) : (
                  <div className="drawer-cart-item-info-empty-cart">
                    YOUR BAG IS EMPTY
                  </div>
                )}
              </div>
            </div>
            {this.state.cart && this.state.cart?.length > 0 && (
              <div className="drawer-footer drawer-container-bordered">
                <p className="cart-footer-text">
                  Shipping calculated at checkout
                </p>
                <SlideButton
                  className="drawer-button"
                  color="primary"
                  onClick={() => window.location.assign("/checkout")}
                >
                  <span>Checkout</span>
                  <span className="separator-dot" />
                  {!!this.state.wholeCart?.total && (
                    <span>
                      {
                        JSON.parse(
                          window.localStorage.getItem("countryCode") ?? "{}"
                        )?.countryCode
                      }{" "}
                      {parseFloat(this.state.wholeCart.total).toFixed(0)}
                    </span>
                  )}
                </SlideButton>
              </div>
            )}
          </div>
        </SwipeableDrawer>
      </ClickAwayListener>
    );
    // Customizable Area End
  }
}

// Customizable Area End
export default SwipeCart;

// Customizable Area Start
// Customizable Area End
