import React from "react";
import classNames from "classnames";
import { Button } from "reactstrap";

import "./slideButton.css";

const SlideButton = (props: any) => {
  const { children, className, onClick, size, fixedFontSize, reverseColor, spanClass, ...rest } = props;

  let buttonBaseClass = "slide-btn";
  if (reverseColor) {
    buttonBaseClass = "slide-btn-reverse-color";
  }
  return (
    <Button
      type="button"
      {...rest}
      className={classNames(
        buttonBaseClass,
        className,
        fixedFontSize ? "fixed-font-size" : ""
      )}
      // color="primary"
      onClick={onClick}
      size={size}
    >
      <span className={spanClass}>{children}</span>
    </Button>
  );
};

SlideButton.defaultProps = {
  className: "",
  onClick: () => {},
  title: "",
  size: "sm",
  reverseColor: false,
};

export default SlideButton;
